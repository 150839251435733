import React from 'react'
import ReactPlayer from 'react-player/lazy'
const VideoBox = ({ url, parameters,styles = {},onVideoPlay  = {}, aiStatus, renderAiOverlay}) => {
    return (
        <div>
            <ReactPlayer className='react-player' onPlay={onVideoPlay} style={{
                maxHeight: "10vw",
                ...styles
            }} url={url} width='100%'
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }} controls styles={{}} {...parameters} />
            {renderAiOverlay(aiStatus)}
        </div>
    )
}

export default VideoBox