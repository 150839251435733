import React from "react";
import { Youtube } from "react-feather";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VideoBox from "app/shared/components/ReusableComponents/VideoPlayer/container";
import img from "assets/images/no-image2.jpg";
import Lightbox from "react-image-lightbox";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx"
export const CarouselComponent = ({
  wrapperClassName,
  carouselProps,
  items = [],
  isExtendView = false,
  displayEmptyIndicator = true,
}) => {
  const [fsLightParams, setFsLightParams] = React.useState({
    lightboxactive: false,
    evidencelightbox: [],
  });

  const __renderAiOverlay = (aiStatus) => {
    if(aiStatus == "1"){
      return <div className="ai-overlay-verify">AI <IoMdCheckmarkCircleOutline /></div>;
    }else if(aiStatus == undefined){
      return;
    }else {
      return <div className="ai-overlay-unverify">AI <RxCrossCircled /></div>;
    }
  }

  const __renderCarouselContent = (element) => {
    const { url, contentType, aiStatus } = element ?? {};
    if (contentType === "image") {
      return (
        <>
        <img
          src={url}
          className="cursor-pointer"
          style={{ maxHeight: "15vw", width: "100%", height: "auto", display: "block" }}
          alt="asset"
          onClick={() => {
            console.log("working")
            setFsLightParams((prevState) => ({
              ...prevState,
              lightboxactive: true,
              evidencelightbox: [url],
            }));
          }}
        />
        {__renderAiOverlay(aiStatus)}
        </>
      );
    } else if (contentType === "video") {
      return <VideoBox url={url} aiStatus={aiStatus} renderAiOverlay = {__renderAiOverlay} />;
    }
  };
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "#000",
  };

  const indicatorStyles = {
    background: "#D9D9D9",
    borderRadius: "13px",
    width: "1.5vw",
    cursor: "pointer",
    height: 8,
    display: "inline-block",
    margin: "0 8px",
  };
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );
  const customRenderThumb = (children) =>
    children.map((item) => {
      if (item.props.contentType === "image") {
        return <img src={item.props.itemUrl} />;
      } else if (item.props.contentType === "video") {
        return (
          <Youtube
            style={{
              display: "block",
              width: "inherit",
              height: "inherit",
              padding: "0px 10px",
            }}
          />
        );
      }
    });
  return (
    <>
      
      {
      fsLightParams?.lightboxactive && (
        <Lightbox
          mainSrc={fsLightParams.evidencelightbox}
          onCloseRequest={() => setFsLightParams({
            lightboxactive: false,
            evidencelightbox: [],
          }) }
        // imageTitle={<h2>Do's and Don'ts</h2>}
        />
      )
    }
      <div
        className={`${wrapperClassName} ${
          !isExtendView ? "remove-thumbnail" : ""
        } ${items.length === 1 ? "remove-indicator" : ""}`}
      >
        {items.length ? (
          <Carousel
            {...carouselProps}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                // <ChevronLeft title={label} onClick={onClickHandler} style={{ ...arrowStyles, left: 15 }} />
                <></>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <></>
                // <ChevronRight onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }} />
              )
            }
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: "#000" }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
            renderItem={customRenderItem}
            renderThumbs={customRenderThumb}
          >
            {items?.map((el, idx) => (
              <div key={idx} contentType={el.contentType} className="cursor-pointer" style={{position: "relative", display: "inline-block"}} itemUrl={el.url} onClick={() => {
                setFsLightParams((prevState) => ({
                  ...prevState,
                  lightboxactive: true,
                  evidencelightbox: el.url,
                }));
              }}>
                {" "}
                {__renderCarouselContent(el)}{" "}
              </div>
            ))}
          </Carousel>
        ) : (
          <div>
            {displayEmptyIndicator ? (
              <img src={img} className="img-fluid" />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
