/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Component } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Accordion,
  OverlayTrigger,
  Popover,
  Modal,
} from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { ResolvePopup, InviteUser } from "app/shared/components/Popups";
import {
  ChevronDown,
  ChevronRight,
  Send,
  Info,
  ChevronLeft,
  FileText,
  Eye,
  BarChart2,
  Triangle,
} from "react-feather";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import AudioPlayer from "react-h5-audio-player";
import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";

import ReactTooltip from "react-tooltip";
import LoadingView from "app/shared/components/Loader/LoadingView";
import { requiredMessage } from "app/shared/constants/Messages";
import { Scrollbars } from "react-custom-scrollbars";
import { HorizontaTimeline } from "app/views/Form/UserFormView/UserFormView";
import { ChatMessageBox } from "app/views/TaskManagement/TaskDetail";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import HighBadgeIcon from "assets/sls-icons/Risk Level/High.svg";
import MediumBadgeIcon from "assets/sls-icons/Risk Level/Medium.svg";
import LowBadgeIcon from "assets/sls-icons/Risk Level/Low.svg";
import ExtremeBadgeIcon from "assets/sls-icons/Risk Level/Extreme.svg";
import OverdueIcon from "assets/images/icons/overdue.svg";
import { TreeUiComponent } from "app/shared/components/ReusableComponents/TreeView";
import DiscussIcon from "assets/images/icons/discuss.svg";
import AssignedIcon from "assets/images/icons/account.svg";
import EscalatedIcon from "assets/images/icons/escalated.svg";
import ResolvedIcon from "assets/images/icons/resolved.svg";
import ControlMeasureIcon from "assets/images/icons/controlmeasure.svg";
import { convertDateFromTimezoneIST } from "app/shared/utility/tzUtilFunction";
import img from "assets/images/no-image2.jpg";
import _ from "lodash";
import {
  Modal as SLDModal,
  Button as SLDButton,
} from "@salesforce/design-system-react";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import { config } from "config.js";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const qs = require("qs");

export const badgeEnum = {
  Extreme: ExtremeBadgeIcon,
  "High Critical": ExtremeBadgeIcon,
  High: HighBadgeIcon,
  Medium: MediumBadgeIcon,
  Low: LowBadgeIcon,
};
const mappingName = {
  Department: "deptAnalysisList",
  Contractor: "contractorAnalysisList",
  Zone: "zoneAnalysisList",
  Location: "locationAnalysisList",
};
export const getUserProfileDetails = (id, arr = []) => {
  let cache = {};
  return ((id, arr) => {
    if (id in cache) {
      return cache[id];
    } else {
      cache[id] = arr.find((el) => {
        if (el.userId === id) {
          return el;
        }
      });
      return cache[id];
    }
  })(id, arr);
};
class ReportingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showUser: false,
      reportDetail: [],
      userLists: [],
      soiId: this.props.match.params.id,
      resolveFlag: this.props.match.params.flag,
      originFlag: this.props.match.params.origin,
      countModalParams: {
        isOpen: false,
        moduleName: "",
        moduleData: [],
      },
      isCommentApiLoading: false,
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      comment: 0,
      commentAdd: "",
      replycommentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      assignedDetails: {},
      toggler: false,
      deletePopup: false,
      userLikedPopup: false,
      fsToggler: false,
      userLike: [],
      likeNumber: "",
      imageType:
        this.props.match?.params?.flag == "open"
          ? "evidence"
          : "evidence_resolve",
      escalatedList: [],
      controlMeasureList: [],
      showContractor: false,
      showZone: false,
      showLocation: false,
      projectList: [],
      gpcount: [],
      tbtcount: [],
      trainingInternalCount: [],
      trainingExternalCount: [],
      trainingEmployeeCount: [],
      trainingContractorCount: [],
      commentError: false,
    };
    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  _fetchProjectListFlag = async () => {
    let requestData = {
      service_id: this.state.reportDetail.service_id,
      module_name: "uauc",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  async reportDetail() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (this.state.resolveFlag == "resolve") {
      var datailUrl = Url.soiDashboard.resolveDetailList;
    } else {
      var datailUrl = Url.soiDashboard.openDetailList;
    }

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          soi_id: this.state.soiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState(
            {
              reportDetail: response.data.data,
              controlMeasureList: response.data.data.control_measure_list,
              gpcount: response.data.data.gp_count?.length
                ? response.data.data.gp_count
                : [{ gp_count: 0 }],
              tbtcount: response.data.data.tbt_count?.length
                ? response.data.data.tbt_count
                : [{ tbt_count: 0 }],
              trainingInternalCount: response.data.data.training_internal_count
                ?.length
                ? response.data.data.training_internal_count
                : [{ training_internal_count: 0 }],
              trainingExternalCount: response.data.data.training_external_count
                ?.length
                ? response.data.data.training_external_count
                : [{ training_external_count: 0 }],
              trainingEmployeeCount: response.data.data.training_employee_count
                ?.length
                ? response.data.data.training_employee_count
                : [{ training_employee_count: 0 }],
              trainingContractorCount: response.data.data
                .training_contractor_count?.length
                ? response.data.data.training_contractor_count
                : [{ training_contractor_count: 0 }],
              // gpcount: response.data.data.gp_count,
              // tbtcount: response.data.data.tbt_count,
              // trainingInternalCount: response.data.data.training_internal_count,
              // trainingExternalCount: response.data.data.training_external_count,
              // trainingEmployeeCount: response.data.data.training_employee_count,
              // trainingContractorCount:
              //   response.data.data.training_contractor_count,
            },
            () => this.fetchReportingPerson(response.data.data.reported_by_id)
          );
          this.escalatedList();
          this._fetchProjectListFlag();
        }
        // console.log(this.state.reportDetail)
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  async escalatedList() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var datailUrl = Url.soiDashboard.escalatedList;

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.reportDetail.service_id,
          project_id: this.state.reportDetail.project_id,
          oiac_id: this.state.soiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          const { reportDetail } = this.state;
          const escaletedIds = [];
          if (response.data.data.list?.length) {
            response.data.data.list.forEach((data) => {
              data.user_list.forEach((user) => {
                escaletedIds.push(user.id);
              });
            });
          }
          const voilatorIds =
            reportDetail?.incident_person_details.map((el) =>
              parseInt(el?.searched_incident_person_id)
            ) ?? [];
          const assignId =
            reportDetail?.assigned_user_id !== 0
              ? reportDetail?.assigned_user_id
              : this.state.userData?.user_id;
          const pendingDetailsIds =
            [
              assignId,
              ...reportDetail.invited_user.map((element) => element.userid),
              ...escaletedIds,
              ...voilatorIds,
            ] ?? [];
          if (pendingDetailsIds.length) {
            this.fetchUsersList([...new Set(pendingDetailsIds)]);
          }
          this.setState({ escalatedList: response.data.data.list });
        }
        // console.log(this.state.reportDetail)
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    console.log("selected user === " + selectedUserId);

    await axios
      .post(
        Url.smDashboard.removeInviteUser,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          selected_user_id: selectedUserId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "User removed successfully",
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not removed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentList = async (limitComment = 0) => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          report_type: "soi",
          limit: limitComment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
          this.myChatRef.scrollToBottom(0, 0);
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };
  subCommentList = async () => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ subcommentlist: response.data.data, comment: 1 });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.soiId,
            module: "soi",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "" });
            this.myChatRef.scrollToBottom(0, 0);
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "" });
          this.myChatRef.scrollToBottom(0, 0);
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var reportData = this.state.reportDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: reportData.service_id,
          project_id: reportData.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (SoiId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.soiDashboard.soiopendelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          soi_id: SoiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "UA/UC Deleted Successfully",
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: "UA/UC Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          report_type: "soi",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.soiId;
    //console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.user_id));

    var data = {
      auth_key: token,
      user_id: userId,
      report_id: reportId,
      selected_user_id: selectedUserIds,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);

      if (property == "selected_user_id") {
        data[property].map((item, key) =>
          formBody.push(encodedKey + "=" + item)
        );
      } else {
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }
    formBody = formBody.join("&");

    await axios
      .post(Url.smDashboard.addInviteUser, formBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({ message: "User added successfully", type: "success" });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not added. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.CommentList();
    this.reportDetail();
    this.UserWhoLiked();
  }
  // componentDidUpdate() {
  //   if (this.state.soiId && this.state.dataLoading === false) {

  //   }

  // }
  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  renderTimeLine = (reportData, state) => {
    let details = [
      {
        title: <span className="font-14">Reported Details</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15vw" }}>
            <img
              src={reportData.reported_by_pic}
              className="user-icon"
              title={reportData.reported_by}
            />
            <div>
              <div className="common-grey-1 font-14">Reported date & time </div>
              <div className="common-black-1 font-14 font-weight-500">
                {`${reportData.submission_date || "18/05/2000"} ${
                  reportData.submission_time
                }`}{" "}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-14">Assigned To Details</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "11vw" }}>
            <img
              src={reportData.assigned_user_pic}
              className="user-icon"
              title={reportData.assigned_user}
            />
            <div>
              <div className="common-grey-1 font-14">Due Date </div>
              <div className="common-black-1 font-14 font-weight-500">
                {reportData.target_date || "17/05/2000"}{" "}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-14">Closing Details</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "11vw" }}>
            <img
              src={reportData.assigned_user_pic}
              className="user-icon"
              title={reportData.assigned_user}
            />
            <div>
              <div className="common-grey-1 font-14">Closed Date</div>
              <div className="common-black-1 font-14 font-weight-500">
                {" "}
                {reportData.closing_date}{" "}
              </div>
            </div>
          </div>
        ),
        skip: state.resolveFlag == "resolve" ? false : true,
      },
      {
        title: (
          <span className="font-14">{`${
            state.resolveFlag === "open" ? "Overdue" : "Deviation"
          } Details`}</span>
        ),
        cardTitle: (
          <div className="d-flex  " style={{ width: "11vw" }}>
            <img
              src={reportData.assigned_user_pic}
              className="user-icon"
              title={reportData.assigned_user}
            />
            <div>
              <div className="common-grey-1 font-14">
                {state.resolveFlag === "open" ? "Overdue" : "Deviation"}
              </div>
              <div className="common-black-1 font-14 font-weight-500">
                {" "}
                {state.resolveFlag === "open"
                  ? reportData.overdue
                  : reportData.deviation}{" "}
              </div>
            </div>
          </div>
        ),
        skip:
          (reportData.overdue != undefined && reportData?.overdue != 0) ||
          (reportData.deviation != undefined && reportData?.deviation != 0)
            ? false
            : true,
      },
    ];

    if (reportData.invited_user?.length) {
      details = [
        ...details,
        ...reportData.invited_user?.map((image, index) => {
          return {
            title: <span className="font-14">Invited Users </span>,
            cardTitle: (
              <div className="d-flex   " style={{ width: "11vw" }}>
                <img
                  className="user-icon "
                  src={image.userpic}
                  alt=""
                  title={image.username}
                />

                <div>
                  <div className="common-grey-1 font-14">Date & Time</div>
                  <div className="common-black-1 font-14 font-weight-500">
                    {" "}
                    {convertDateFromTimezoneIST(image.invite_date) || "NA"}{" "}
                  </div>
                </div>
              </div>
            ),
            skip:
              (reportData.overdue != undefined && reportData?.overdue != 0) ||
              (reportData.deviation != undefined && reportData?.deviation != 0)
                ? false
                : true,
          };
        }),
      ];
    }
    if (this.state.escalatedList.length > 0) {
      details = [
        ...details,
        ...this.state.escalatedList?.map((data) =>
          data.user_list.map((user, idx) => {
            return {
              title: (
                <span className="font-14">
                  {" "}
                  {` Escalated  ${data.level_title}`}
                </span>
              ),
              cardTitle: (
                <div className="d-flex   " key={idx} style={{ width: "11vw" }}>
                  <img
                    className="user-icon "
                    src={user.pic}
                    alt=""
                    title={user.name}
                  />

                  <div>
                    <div className="common-grey-1 font-14">Name</div>
                    <div className="common-black-1 font-14 font-weight-500">
                      {" "}
                      {user.name}{" "}
                    </div>
                    <div className="common-grey-1 font-14">Date & Time</div>
                    <div className="common-black-1 font-14 font-weight-500">
                      {" "}
                      {data.escalatedDate} {data.escalatedTime}
                    </div>
                  </div>
                </div>
              ),
              skip:
                (reportData.overdue != undefined && reportData?.overdue != 0) ||
                (reportData.deviation != undefined &&
                  reportData?.deviation != 0)
                  ? false
                  : true,
            };
          })
        ),
      ];
    }
    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <span className="font-14">Latest Comment</span>,
          cardTitle: (
            <div
              className="d-flex  cursor-pointer "
              style={{ width: "11vw" }}
              onClick={() => {
                this.myRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img src={user_pic} className="user-icon" title={user_name} />
              <div>
                <div className="common-grey-1 font-14">Message</div>
                <div className="common-black-1 font-14 font-weight-500">
                  {" "}
                  {comment}{" "}
                </div>
                <span className="font-12 common-grey-1">{comment_time}</span>
              </div>
            </div>
          ),
        },
      ];
    }

    return details;
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      content.push({
        aiStatus: data?.ai_status,
        url: data.link,
        contentType:
          data.format != "text"
            ? data.format == "image" || data.format == "img"
              ? "image"
              : "video"
            : "video",
      });
    });
    return content;
  };

  __generateTreeNodes = (data, activeNode = "") => {
    const nodes = activeNode.split(" ");
    const treeNodes = [];
    const nodeNames = data.map((el) => el.level_id);
    nodeNames.forEach((node, idx) => {
      if (data[idx].level_id === node)
        treeNodes.push({
          label:
            data[idx].level_title === nodes[0].trim()
              ? activeNode
              : data[idx].level_title,
          date: `${data[idx].escalatedDate}  ${data[idx].escalatedTime}`,
          children: data[idx].user_list,
        });
    });
    return treeNodes;
  };
  __renderTreeTemplate = (parent, children) => {
    return (
      <div
        className="feild-group common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
        style={{ maxWidth: "230px" }}
      >
        <div className="output-text ">
          <span className="label mt-3 font-14 common-black-1 font-weight-500">
            {children.name}
          </span>
          <div className="image-box my-3" style={{ marginRight: "0.5vw" }}>
            <GetPopoverFrame
              element={
                <>
                  <img src={children.pic} alt="" className="cursor-pointer" />
                </>
              }
              mappingKeys={() => {
                const detail =
                  getUserProfileDetails(children?.id, this.state.userLists) ??
                  {};
                return {
                  ID: detail["employeeId"] ?? "NA",
                  Department: detail["field"]?.split(":")[1] ?? "NA",
                  Type: detail["userType"] ?? "NA",
                  Designation: detail["designation"] ?? "NA",
                  Contact: detail["contact_number"] ?? "NA",
                  // Zone: detail['designation'],
                  // Location: detail['designation'],
                };
              }}
            />
          </div>
          <div className="feild-group d-flex justify-content-center">
            <div className=" label font-14 font-weight-500  common-black-1">
              {parent.date}
            </div>
          </div>
        </div>
      </div>
    );
  };
  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={75}
          cutout={"55%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };

  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);

  onViewCountDetails = (moduleName = "") => {
    console.log(this.state.reportDetail[mappingName[moduleName]]);
    this.setState({
      countModalParams: {
        isOpen: true,
        moduleName,
        moduleData: this.state.reportDetail[mappingName[moduleName]],
      },
    });
  };

  generateBarData = (data = []) => {
    return {
      data: {
        labels: data.map((item) => item.name),
        datasets: [
          {
            label: "Open Count",
            data: data.map((item) => item.open_count),
            backgroundColor: data.map((item) => item.openCntcolor),
            color:"white"
          },
          {
            label: "Close Count",
            data: data.map((item) => item.close_count),
            backgroundColor: data.map((item) => item.closeCntcolor),
            color:"white"
          },
        ],
      },
    };
  };
  render() {
    // Chart options
   
    const barGraphOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          backgroundColor: "white",
          bodyColor: "#666666",
          bodyFont: {
            weight: 500,
          },
          borderColor: "rgba(0, 0, 0, 0.08)",
          borderWidth: 2,
          cornerRadius: 4,
          bodySpacing: 4,
          padding: {
            top: -10,
            bottom: 8,
            left: 0,
            right: 0,
          },
          bodyAlign: "center",
          displayColors: false,
          titleColor: "transparent",
        },
        datalabels: {
          display: true,
          align: "top",
          anchor: "end",
          color: "black",
        },
      },
      scales: {
        x: {
          display: true,
          offset: true,
          grid: {
            display: false,
          },
          ticks: {
            color: "#666666",
            font: {
              size: 11,
              weight: "500",
            },
            padding: 0,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          grid: {
            color: "#ececec",
          },
          ticks: {
            color: "#666666",
            font: {
              size: 11,
              weight: "500",
            },
            padding: 20,
          },
        },
      },
    };

    var reportData = this.state.reportDetail;
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const { userData } = this.state;
    const isReportedUserEnabled =
      reportData.reported_by_id === userData.user_id;
    const isApproveEnabled = [3, 1, 5].includes(userData?.designation_flag);
    console.log("isApproveEnabled ->" + isApproveEnabled);
    console.log(
      "isReportedUserEnabled",
      isReportedUserEnabled,
      isApproveEnabled
    );

    if (this.state.dataLoading == false) {
      var evidencePics =
        this.state.reportDetail && this.state.reportDetail.evidence
          ? this.state.reportDetail.evidence.map((item) => {
              return {
                url: item.link,
                type: item.format,
              };
            })
          : [];

      if (
        this.state.imageType &&
        this.state.imageType == "evidence" &&
        this.state.reportDetail.evidence
      ) {
        sourcesList = this.state.reportDetail.evidence.map((item) => item.link);
      } else {
        sourcesList = this.state.reportDetail.evidence_resolve.map(
          (item) => item.link
        );
      }

      if (this.state.imageType && this.state.imageType == "evidence") {
        sourcesType = this.state.reportDetail.evidence.map(
          (item) => item.format
        );
      } else {
        sourcesType = this.state.reportDetail.evidence_resolve.map((item) =>
          item.format == "img" ? "image" : item.format
        );
      }
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/ua-uc/open`} />;
    }

    let uaUcChartData = [];
    if (this.state.reportDetail?.ua_number != undefined) {
      uaUcChartData = [
        {
          y: reportData?.ua_number,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: reportData?.uc_number,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            { y: reportData.ua_number, name: "Unsafe Act", color: "#C7AC83" },
            {
              y: reportData.uc_number,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let data = [
      {
        y: reportData.extreme_number,
        color: reportData.extreme_number_color,
        name: "Extreme",
      },
      {
        y: reportData.high_number,
        color: reportData.high_number_color,
        name: "HIGH",
      },
      {
        y: reportData.medium_number,
        color: reportData.medium_number_color,
        name: "MEDIUM",
      },
      {
        y: reportData.low_number || 0,
        color: reportData.low_number_color,
        name: "LOW",
      },
    ];

    const severityChartData = {
      labels: ["Extreme", "High", "Medium", "Low"],
      datasets: [
        {
          label: "",
          maxBarThickness: 30,
          backgroundColor: data.map((data) => data.color),
          data: data.map((data) => data.y),
        },
      ],
    };

    const severityChart = {
      chart: {
        type: "column",
        height: 150,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      xAxis: {
        categories: ["Extreme", "High", "Medium", "Low"],
        lineColor: "#000000",
      },
      yAxis: {
        min: 0,
        // max: 5,
        tickInterval: 1,
        title: {
          enabled: false,
        },
        allowDecimals: false,
        gridLineColor: "rgba(0, 0, 0, 0.04)",
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.extreme_number,
              color: reportData.extreme_number_color,
              name: "Extreme",
            },
            {
              y: reportData.high_number,
              color: reportData.high_number_color,
              name: "HIGH",
            },
            {
              y: reportData.medium_number,
              color: reportData.medium_number_color,
              name: "MEDIUM",
            },
            {
              y: reportData.low_number || 0,
              color: reportData.low_number_color,
              name: "LOW",
            },
          ],
        },
      ],
    };

    let nmuaUcChartData = [];

    if (this.state.reportDetail?.nm_ua_number != undefined) {
      nmuaUcChartData = [
        {
          y: reportData?.nm_ua_number,
          name: "Unsafe Act",
          color: reportData?.nm_ua_color,
        },
        {
          y: reportData?.nm_uc_number,
          name: "Unsafe Condition",
          color: reportData?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.nm_ua_number,
              name: "Unsafe Act",
              color: reportData.nm_ua_color,
            },
            {
              y: reportData.nm_uc_number,
              name: "Unsafe Condition",
              color: reportData.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let analysisChartData = [];
    if (this.state.reportDetail?.activityAnalysis != undefined) {
      analysisChartData = [
        {
          y: reportData?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: reportData?.rta_number ?? 0,
          name: "RTA",
          color: "#FAAC15",
        },
        {
          y: reportData?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: reportData?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: reportData.activityAnalysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: reportData.activityAnalysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: reportData.activityAnalysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            { y: reportData.fa_number, name: "FA", color: "#FF4242" },
            { y: reportData.rta_number, name: "RTA", color: "#FAAC15" },
            { y: reportData.mtc_number, name: "MTC", color: "#086992" },
            { y: reportData.lti_number, name: "LTI", color: "#18DA07" },
            {
              y: reportData.activityAnalysis?.notable_number || 0,
              name: "Notable",
              color: "#rgb(161, 255, 206)",
            },
            {
              y: reportData.activity_analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: reportData.activity_analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //GP Count
    const gpCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const intetlCount = this.state.trainingInternalCount?.length
      ? this.state.trainingInternalCount.map(
          (data) => data.training_internal_count
        )
      : 0;
    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          intetlCount +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount?.length
              ? this.state.trainingInternalCount.map(
                  (data) => data.training_internal_count
                )
              : "0",
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
    const externalC = this.state.trainingExternalCount?.length
      ? this.state.trainingExternalCount.map(
          (data) => data.training_external_count
        )
      : 0;
    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          //  this.state.trainingExternalCount.map((data) => data.training_external_count) +
          externalC +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color) ?? [],
      series: [
        {
          // data: [this.state.trainingExternalCount.map((data) => data.training_external_count)],
          data: [
            this.state.trainingExternalCount.length > 0
              ? this.state.trainingExternalCount.map(
                  (data) => data.training_external_count
                )
              : 0,
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    if (this.state.originFlag == "thgD") {
      var originUrl = {
        pathname: `/`,
      };
    } else if (this.state.originFlag == "thgPO") {
      var originUrl = {
        pathname: `/ua-uc/open`,
      };
    } else if (this.state.originFlag == "thgPR") {
      var originUrl = {
        pathname: `/ua-uc/resolve`,
      };
    }
    return (
      <div className="report-detail bg-white">
        <FsLightbox
          toggler={this.state.fsToggler}
          types={sourcesType}
          sources={sourcesList}
          disableLocalStorage={true}
          key={sourcesList}
        />
        {/* <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <img
            className="loader-img"
            src={require("assets/images/loader1.gif")}
          />
        </div> */}
        <LoadingView showLoading={this.state.dataLoading} />

        <div className="rd-content d-flex flex-column p-5 ">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div>
              <h2>
                <Link to={originUrl}>
                  <Button className="back-btn">
                    <i
                      className="fa fa-angle-left fa-color"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </Link>
              </h2>
            </div>
            <div>
              <span className="common-black-1 font-weight-500 font-20">
                {" "}
                {this.state.soiId} &nbsp;
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {" "}
                  {reportData.act_type} - {reportData.activity}
                </span>
                <span
                  style={{
                    borderLeft: "3px Solid Orange",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {reportData.reportedmonth_name}
                </span>
              </span>
            </div>

            <div className="d-flex align-items-center">
              {this.state.resolveFlag == "open" ? (
                reportData.closing_tab_display == 1 && (
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      height: "2.3vw",
                      width: "5vw",
                      color: "white",
                    }}
                  >
                    <img
                      onClick={this.openResolve}
                      src={require("assets/sls-icons/V2. New Development/Resolve.svg")}
                      className="mr-1"
                    />
                  </button>
                )
              ) : (
                <span className=" font-weight-500"> Resolved </span>
              )}
              {reportData.edit_flag == 1 && (
                <Link
                  to={`/edit-reporting-detail/${reportData.id}/${reportData.service_id}/${reportData.project_id}/edit`}
                >
                  <img
                    src={require("assets/sls-icons/V2. New Development/Edit.svg")}
                    className="common-grey-1 mr-1 svg-icons"
                  />
                </Link>
              )}
              {(isApproveEnabled || isReportedUserEnabled) &&
                this.state.resolveFlag == "open" && (
                  <img
                    src={require("assets/sls-icons/V2. New Development/Add.svg")}
                    className=" cursor-pointer common-grey-1 mr-1 svg-icons"
                    onClick={this.openInviteUser}
                  />
                )}
              {reportData.delete_status == 1 ? (
                <div className="rd-btn ">
                  <img
                    src={require("assets/sls-icons/V2. New Development/delete.svg")}
                    className=" cursor-pointer common-grey-1 svg-icons mr-1"
                    onClick={() => this.showModal()}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex">
            <div
              className="horizontal-timeline-wrapper shadow-none border  mb-4 "
              style={{ width: "100%" }}
            >
              <div className="timeline-header">
                <p className="common-black-1 font-weight-500">Timeline</p>
              </div>
              <div>
                {Object.keys(reportData).length > 0 ? (
                  <HorizontaTimeline
                    data={this.renderTimeLine(reportData, this.state)}
                    reInitlization={[reportData, this.state]}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex  ">
            <div className="rd-left-content mt-5 pl-0">
              <VerticalTimeline
                layout={"1-column-left"}
                className="m-0"
                lineColor={"#7D6767"}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: this.state.resolveFlag === "open" ? "30vw" : "38vw",
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  icon={<FileText />}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5vw",
                      }}
                      className="mb-5"
                    >
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Reporting Details
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="rd-box w-75">
                          <div className="label mb-3 font-weight-500 common-black-1">
                            View Evidence
                          </div>
                          <div className="rd-box-bottom">
                            <div className="images-section">
                              {this.state.reportDetail.evidence &&
                              this.state.reportDetail.evidence.length == 0 ? (
                                <div>
                                  <img src={img} className="img-fluid" />
                                </div>
                              ) : (
                                <>
                                  <CarouselComponent
                                    items={this.__generateCarouselData(
                                      this.state.reportDetail.evidence
                                    )}
                                    showArrows={false}
                                  />
                                  {/* {
                                  this.state.reportDetail.evidence &&
                                  this.state.reportDetail.evidence.map(
                                    (data, index) => (
                                      <div
                                        className="image-box  w-100 h-100"
                                        key={index}
                                        onClick={() => {
                                          this.setState({
                                            lightboxactive: true,
                                            toggler: true,
                                            fsToggler: !this.state.fsToggler,
                                            imageType: "evidence",
                                          });
                                        }}
                                      >
                                        {data.format != "text" ? (
                                          data.format == "image" ||
                                            data.format == "img" ? (
                                            <img
                                              src={data.link}
                                              alt="evidence image"
                                              className="cursor-pointer"
                                            />
                                          ) : (
                                            <video poster="">
                                              <source
                                                src={data.link}
                                                type="video/mp4"
                                              />
                                            </video>
                                          )
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    )
                                  )} */}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <div className="label mb-3 font-weight-500 common-black-1 ">
                            Observation
                          </div>
                          <div className="rd-box-bottom">
                            <Row>
                              <Col>
                                <div className="feild-group rd-box">
                                  {reportData.observation != "" && (
                                    <div className="output-text">
                                      {reportData.observation}
                                    </div>
                                  )}
                                </div>
                                <div className="feild-group rd-box ">
                                  {/* <span className="mic-icon"><Mic /></span> */}
                                  {reportData.observation_recording !=
                                    undefined &&
                                    reportData.observation_recording.substr(
                                      reportData.observation_recording.length -
                                        3
                                    ) == "mp3" && (
                                      <AudioPlayer
                                        src={reportData.observation_recording}
                                        showJumpControls={true}
                                        layout="horizontal-reverse"
                                        customVolumeControls={[]}
                                        customAdditionalControls={[]}
                                        className="border-0  shadow-none"
                                      />
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-between flex-column">
                            {CheckFormSettingField(
                              reportData.form_setting?.setting,
                              "hazard"
                            ) && reportData?.hazard ? (
                              <div className="feild-group d-flex">
                                <div className="label mb-3 common-black-1 font-weight-500">
                                  Hazard
                                </div>
                                <label className="output-text ml-4">
                                  {reportData.hazard}
                                </label>
                              </div>
                            ) : null}
                            <div className="feild-group d-flex align-items-center">
                              <div className="label mb-2 common-black-1 font-weight-500 mr-4">
                                Risk Level
                              </div>
                              <img
                                src={badgeEnum[reportData.severity]}
                                style={{ width: "30px", height: "30px" }}
                              />
                              <label className="output-text ml-3">
                                {reportData.severity}
                              </label>
                            </div>
                            <div className="feild-group d-flex align-items-center my-2">
                              <div className="label mb-2 common-black-1 font-weight-500 mr-4">
                                Source
                              </div>

                              <label className="output-text ml-3">
                                {reportData.source}
                              </label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div></div>
                </VerticalTimelineElement>
                {this.state.resolveFlag == "resolve" ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                      boxShadow: "none",
                      textAlign: "center",
                    }}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    icon={
                      <img
                        src={ResolvedIcon}
                        className="px-2 w-100  h-100 py-2"
                      />
                    }
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div
                      style={{
                        background: "rgba(217, 217, 217, 0.5)",
                        borderRadius: "28px",
                        width: "max-content",
                      }}
                      className="px-3 py-2 common-black-1 font-weight-500 text-center"
                    >
                      Resolved Details
                    </div>
                    <div className="mt-5"></div>
                    <Row>
                      <Col md={6}>
                        <div className="text-left rd-box w-75">
                          <div class="label common-black-1 font-weight-500">
                            Resolving evidence
                          </div>
                          <div className="rd-box-bottom mt-4">
                            <div className="images-section">
                              {this.state.reportDetail.evidence_resolve &&
                              this.state.reportDetail.evidence_resolve.length ==
                                0 ? (
                                <div>
                                  <img src={img} className="img-fluid" />
                                </div>
                              ) : (
                                <CarouselComponent
                                  items={this.__generateCarouselData(
                                    this.state.reportDetail.evidence_resolve
                                  )}
                                  showArrows={false}
                                />
                                // this.state.reportDetail.evidence_resolve &&
                                // this.state.reportDetail.evidence_resolve.map(
                                //   (data, index) => (
                                //     <div
                                //       className="image-box h-100 w-100"
                                //       key={index}
                                //       onClick={() => {
                                //         this.setState({
                                //           lightboxactive: true,
                                //           toggler: true,
                                //           fsToggler: !this.state.fsToggler,
                                //           imageType: "evidence_resolve",
                                //         });
                                //       }}
                                //     >
                                //       {data.format != "text" ? (
                                //         data.format == "image" ||
                                //           data.format == "img" ? (
                                //           <img
                                //             src={data.link}
                                //             alt="evidence image"
                                //             className="cursor-pointer"
                                //           />
                                //         ) : (
                                //           <video poster="">
                                //             <source
                                //               src={data.link}
                                //               type="video/mp4"
                                //             />
                                //           </video>
                                //         )
                                //       ) : (
                                //         <div></div>
                                //       )}
                                //     </div>
                                //   )
                                // )
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="rd-box text-left">
                          <div class="label common-black-1 font-weight-500">
                            Resolved Remark
                          </div>
                          <div className="rd-box-bottom ">
                            <Row>
                              <Col>
                                <div className="feild-group rd-box ">
                                  {reportData.closing_remark && (
                                    <div className="output-text">
                                      {reportData.closing_remark}
                                    </div>
                                  )}
                                </div>

                                <div className="feild-group rd-box">
                                  {reportData.closing_remark_recording !=
                                    undefined &&
                                    reportData.closing_remark_recording.substr(
                                      reportData.closing_remark_recording
                                        .length - 3
                                    ) == "mp3" && (
                                      <AudioPlayer
                                        src={
                                          reportData.closing_remark_recording
                                        }
                                        showJumpControls={true}
                                        layout="horizontal-reverse"
                                        customVolumeControls={[]}
                                        customAdditionalControls={[]}
                                      />
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="rd-box text-left">
                          <div class="label common-black-1 font-weight-500">
                            Resolved Date
                          </div>
                          <div className="rd-box-bottom ">
                            <div className="feild-group rd-box">
                              <div className="output-text ">
                                {reportData.closing_date}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </VerticalTimelineElement>
                ) : null}
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={<Info />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <div className="mt-5">
                        <Row
                          style={{
                            marginBottom: "0.5vw",
                            rowGap: "2vw",
                          }}
                          className="text-left"
                        >
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-head-right">
                                {/* {this.state.resolveFlag == "resolve" ? (
    <h4>Resolved</h4>
  ) : null} */}
                              </div>
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18 heading-red-border">
                                  Activity info
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Service
                                      </div>
                                      <div className="output-text">
                                        {reportData.service_name}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="feild-group">
                                      <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                        Plant/Project
                                      </div>
                                      <div className="output-text">
                                        {reportData.project_name}
                                      </div>
                                    </div>
                                  </Col>

                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "barrier"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Barrier
                                        </div>
                                        <div className="output-text">
                                          {reportData.barrier}
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "sub hazard"
                                  ) && reportData.sub_hazard ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                          Sub Hazard
                                        </div>
                                        <div className="output-text">
                                          {reportData.sub_hazard}
                                        </div>
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="rd-box">
                              <div className="rd-box-bottom pt-0">
                                <h3 className="font-weight-500 common-red-3 font-18  heading-red-border ">
                                  Department/Contractor/Location
                                </h3>
                                <Row
                                  style={{
                                    rowGap: "2vw",
                                    marginTop: "1.5vw",
                                  }}
                                >
                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "department"
                                  ) ? (
                                    <Col md={6}>
                                      <div className="feild-group">
                                        <div
                                          className="label mb-3 common-black-1 font-weight-500 font-18"
                                          title="Show Counts"
                                        >
                                          Department &nbsp;{" "}
                                          <BarChart2
                                            className="cursor-pointer"
                                            title="Show Counts"
                                            onClick={() =>
                                              this.onViewCountDetails(
                                                "Department"
                                              )
                                            }
                                          />
                                        </div>
                                        {!reportData.department ? (
                                          <div className="output-text">NA</div>
                                        ) : (
                                          <div className="output-text">
                                            {reportData.department}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "contractor"
                                  ) &&
                                  this.state.showContractor &&
                                  reportData.contractor ? (
                                    <Col md={6}>
                                      {this.state.showContractor &&
                                        reportData.contractor && (
                                          <div className="feild-group ">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Contractor &nbsp;{" "}
                                              <BarChart2
                                                className="cursor-pointer"
                                                title="Show Counts"
                                                onClick={() =>
                                                  this.onViewCountDetails(
                                                    "Contractor"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="output-text">
                                              {reportData.contractor}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "zone"
                                  ) ? (
                                    <Col md={6}>
                                      {this.state.showZone &&
                                        reportData.zone && (
                                          <div className="feild-group">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Zone &nbsp;{" "}
                                              <BarChart2
                                                className="cursor-pointer"
                                                title="Show Counts"
                                                onClick={() =>
                                                  this.onViewCountDetails(
                                                    "Zone"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="output-text">
                                              {reportData.zone}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}

                                  {CheckFormSettingField(
                                    reportData.form_setting?.setting,
                                    "location"
                                  ) ? (
                                    <Col md={6}>
                                      {this.state.showLocation &&
                                        reportData.location && (
                                          <div className="feild-group">
                                            <div className="label mb-3 common-black-1 font-weight-500 font-18">
                                              Location &nbsp;{" "}
                                              <BarChart2
                                                className="cursor-pointer"
                                                title="Show Counts"
                                                onClick={() =>
                                                  this.onViewCountDetails(
                                                    "Location"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="output-text">
                                              {reportData.location}
                                            </div>
                                          </div>
                                        )}
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
                {(CheckFormSettingField(
                  reportData.form_setting?.setting,
                  "control measure"
                ) &&
                  reportData.control_measure) ||
                this.state.controlMeasureList.length > 0 ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      height: "4vw",
                      paddingTop: 0,
                      boxShadow: "none",
                      boxShadow: "none",
                      textAlign: "center",
                    }}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    icon={
                      <img
                        src={ControlMeasureIcon}
                        className="px-2 w-100  h-100"
                      />
                    }
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div className="rd-box-head">
                        <div className="mt-5">
                          <Row
                            style={{
                              marginBottom: "0.5vw",
                            }}
                          >
                            <Col sm={12}>
                              <div className="rd-box text-left">
                                {(reportData.control_measure ||
                                  this.state.controlMeasureList.length > 0) && (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      <div className=" mb-4 font-weight-500 common-red-3 font-18 heading-red-border">
                                        Control Measure
                                      </div>
                                      <div className="w-50">
                                        {reportData.control_measure_recording !=
                                          undefined &&
                                          reportData.control_measure_recording.substr(
                                            reportData.control_measure_recording
                                              .length - 3
                                          ) == "mp3" && (
                                            <AudioPlayer
                                              src={
                                                reportData.control_measure_recording
                                              }
                                              showJumpControls={true}
                                              layout="horizontal-reverse"
                                              customVolumeControls={[]}
                                              customAdditionalControls={[]}
                                              className="border-0  shadow-none"
                                            />
                                          )}
                                      </div>
                                    </div>
                                    <div className="rd-box-bottom">
                                      <Row>
                                        <Col>
                                          {reportData.control_measure && (
                                            <div className="output-text font-18 mb-3">
                                              {reportData.control_measure}
                                            </div>
                                          )}

                                          <div>
                                            {this.state.controlMeasureList.map(
                                              (data, index) => (
                                                <div className="output-text common-card-border-layout-4  px-3 py-2 font-18 shadow-none my-3 ">
                                                  {`${index + 1}. ${
                                                    data.controlMeasure
                                                  }`}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={
                    <img src={AssignedIcon} className="px-2 w-100  h-100" />
                  }
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                          width: "max-content",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500"
                      >
                        Assigned Details
                      </div>
                      <div className="mt-5">
                        <Row
                          style={{
                            marginBottom: "0.5vw",
                          }}
                        >
                          <Col sm={4} className="text-left">
                            <div className="label  mb-4  font-weight-500">
                              Resolve By
                            </div>
                            <div
                              className="feild-group common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
                              style={{
                                maxWidth: "185px",
                              }}
                            >
                              <div className="output-text ">
                                <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                  {reportData.assigned_user}
                                </span>
                                <div
                                  className="image-box my-3 "
                                  style={{ marginRight: "0.5vw" }}
                                >
                                  {/* {GetPopoverFrame(
                                    <img
                                      src={reportData.assigned_user_pic}
                                      alt=""
                                      className="cursor-pointer"
                                    />
                                    , () => {
                                      const detail = getUserProfileDetails(this.state.reportDetail?.assigned_user_id !== 0 ? this.state.reportDetail?.assigned_user_id : this.state.userData?.user_id, this.state.userLists) ?? {}
                                      return {
                                        ID: detail['employeeId'] ?? "NA",
                                        Department: detail['field']?.split(':')[1] ?? "NA",
                                        Type: detail['userType'] ?? "NA",
                                        Designation: detail['designation'] ?? "NA",
                                        Contact: detail['contact_number'] ?? "NA",
                                      }
                                    },
                                    "right"
                                  )
                                  } */}
                                  <GetPopoverFrame
                                    element={
                                      <>
                                        <img
                                          src={reportData.assigned_user_pic}
                                          alt=""
                                          className="cursor-pointer"
                                        />
                                      </>
                                    }
                                    mappingKeys={() => {
                                      const detail =
                                        getUserProfileDetails(
                                          this.state.reportDetail
                                            ?.assigned_user_id !== 0
                                            ? this.state.reportDetail
                                                ?.assigned_user_id
                                            : this.state.userData?.user_id,
                                          this.state.userLists
                                        ) ?? {};
                                      return {
                                        ID: detail["employeeId"] ?? "NA",
                                        Department:
                                          detail["field"]?.split(":")[1] ??
                                          "NA",
                                        Type: detail["userType"] ?? "NA",
                                        Designation:
                                          detail["designation"] ?? "NA",
                                        Contact:
                                          detail["contact_number"] ?? "NA",
                                        // Zone: detail['designation'],
                                        // Location: detail['designation'],
                                      };
                                    }}
                                  />
                                </div>
                                <div className="feild-group d-flex justify-content-center">
                                  <div className="label common-black-1 font-weight-500 font-14 mr-2 heading-red-border pb-0">
                                    Due Date :{" "}
                                  </div>
                                  <div className=" label font-14 font-weight-500">
                                    {reportData.target_date || "17/05/2000"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={8} className="text-left">
                            <div className="label   mb-4  font-weight-500">
                              Invited Team ({reportData.invited_user?.length}){" "}
                            </div>
                            <Scrollbars style={{ height: `10vw` }}>
                              <div className="d-flex">
                                {reportData.invited_user?.map(
                                  (image, index) => (
                                    <div
                                      className="feild-group common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center mr-4 "
                                      key={index}
                                      style={{
                                        maxWidth: "200px",
                                        minWidth: "155px",
                                      }}
                                    >
                                      <div className="output-text ">
                                        <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                          {image.username}
                                        </span>
                                        <div
                                          className="image-box my-3"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          {/* {GetPopoverFrame(
                                            <img
                                              src={image.userpic}
                                              alt=""
                                              className="cursor-pointer"
                                            />
                                            , () => {
                                              const detail = getUserProfileDetails(image.userid, this.state.userLists) ?? {}
                                              return {
                                                ID: detail['employeeId'] ?? "NA",
                                                Department: detail['field']?.split(':')[1] ?? "NA",
                                                Type: detail['userType'] ?? "NA",
                                                Designation: detail['designation'] ?? "NA",
                                                Contact: detail['contact_number'] ?? "NA",
                                                // Zone: detail['designation'],
                                                // Location: detail['designation'],
                                              }
                                            },
                                            "right"
                                          )
                                          } */}
                                          <GetPopoverFrame
                                            element={
                                              <>
                                                <img
                                                  src={image.userpic}
                                                  alt=""
                                                  className="cursor-pointer"
                                                />
                                              </>
                                            }
                                            mappingKeys={() => {
                                              const detail =
                                                getUserProfileDetails(
                                                  image.userid,
                                                  this.state.userLists
                                                ) ?? {};
                                              return {
                                                ID:
                                                  detail["employeeId"] ?? "NA",
                                                Department:
                                                  detail["field"]?.split(
                                                    ":"
                                                  )[1] ?? "NA",
                                                Type:
                                                  detail["userType"] ?? "NA",
                                                Designation:
                                                  detail["designation"] ?? "NA",
                                                Contact:
                                                  detail["contact_number"] ??
                                                  "NA",
                                                // Zone: detail['designation'],
                                                // Location: detail['designation'],
                                              };
                                            }}
                                          />
                                        </div>
                                        <div className="feild-group d-flex justify-content-center">
                                          <div className=" label font-14 font-weight-500 common-black-1 mb-1">
                                            {convertDateFromTimezoneIST(
                                              image.invite_date
                                            ) || "NA"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </Scrollbars>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={
                    <img src={AssignedIcon} className="px-2 w-100  h-100" />
                  }
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                          width: "max-content",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500"
                      >
                        Violator Details
                      </div>
                      <div className="mt-5">
                        <Row
                          style={{
                            marginBottom: "0.5vw",
                          }}
                        >
                          <Col sm={12} className="text-left">
                            <Scrollbars
                              style={{ height: "11vw", width: "40vw" }}
                            >
                              <div className="d-flex ">
                                {reportData.incident_person_details?.map(
                                  (data, index, arr) => {
                                    const detail =
                                      getUserProfileDetails(
                                        parseInt(
                                          data.searched_incident_person_id
                                        ),
                                        this.state.userLists
                                      ) ?? {};
                                    const isUserFromOrgnization =
                                      data?.searched_incident_person_id;
                                    const position =
                                      arr.length - 1 === index
                                        ? "left"
                                        : "right";
                                    return isUserFromOrgnization ? (
                                      <div
                                        className="feild-group common-card-border-layout-4 px-2 py-2 mr-2 rounded-0 shadow-none text-center "
                                        style={{
                                          minWidth: "11vw",
                                        }}
                                      >
                                        <div className="output-text ">
                                          <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                            {data.incident_person_name}
                                          </span>
                                          <div
                                            className="image-box my-3 "
                                            style={{ marginRight: "0.5vw" }}
                                          >
                                            {/* {GetPopoverFrame(
                                          <img
                                            src={detail?.user_pic}
                                            alt=""
                                            className="cursor-pointer"
                                            style={{
                                              height: "2.7vw",
                                              width: "2.7vw"
                                            }}
                                          />
                                          , () => {
                                            return {
                                              ID: detail['employeeId'] ?? "NA",
                                              Department: detail['field']?.split(':')[1] ?? "NA",
                                              Type: detail['userType'] ?? "NA",
                                              Designation: detail['designation'] ?? "NA",
                                              Contact: detail['contact_number'] ?? "NA",
                                            }
                                          },
                                          position
                                        )
                                        } */}
                                            <GetPopoverFrame
                                              element={
                                                <>
                                                  <img
                                                    src={detail?.user_pic}
                                                    alt=""
                                                    className="cursor-pointer"
                                                  />
                                                </>
                                              }
                                              mappingKeys={() => {
                                                return {
                                                  ID:
                                                    detail["employeeId"] ??
                                                    "NA",
                                                  Department:
                                                    detail["field"]?.split(
                                                      ":"
                                                    )[1] ?? "NA",
                                                  Type:
                                                    detail["userType"] ?? "NA",
                                                  Designation:
                                                    detail["designation"] ??
                                                    "NA",
                                                  Contact:
                                                    detail["contact_number"] ??
                                                    "NA",
                                                  // Zone: detail['designation'],
                                                  // Location: detail['designation'],
                                                };
                                              }}
                                            />
                                          </div>
                                          <div className="feild-group d-flex justify-content-center flex-column">
                                            <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                              Id{" "}
                                            </div>
                                            <div className=" label font-14 font-weight-500">
                                              {data?.searched_incident_person_id
                                                ? detail?.employeeId
                                                : detail.userId ?? index + 1}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className="feild-group mr-2 common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
                                          style={{
                                            minWidth: "11vw",
                                          }}
                                          key={index}
                                        >
                                          <div className="output-text ">
                                            <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                              {data.incident_person_name}
                                            </span>
                                            <div className=" my-3">
                                              <img
                                                src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                                                alt=""
                                              />
                                            </div>
                                            <div className="feild-group d-flex justify-content-center flex-column">
                                              <div className="label common-black-1 mx-auto font-weight-500 font-14 mr-2  pb-0">
                                                Id{" "}
                                              </div>
                                              <div className=" label font-14 font-weight-500">
                                                {data.incident_person_id ??
                                                  index + 1}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Scrollbars>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
                {this.state.escalatedList.length > 0 ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    icon={
                      <img src={EscalatedIcon} className="px-2 w-100  h-100" />
                    }
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                          width: "max-content",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        Escalated Details
                      </div>
                      <div className="mt-5">
                        {/* <Row>
                          {this.state.escalatedList.map((data, index) => (<Col key={index}>
                            {data.user_list.map((user, idx) => (<div key={idx}>
                              <div className="mb-3">{data.level_title}</div>
                              <div
                                className="image-box mb-3"
                              >
                                <img
                                  src={user.pic}
                                  alt=""
                                  title={user.name}
                                  style={{ marginRight: "0.5vw" }}
                                />

                              </div>
                              <div>
                                <h6 className="font-weight-500 common-black-1"> {data.escalatedDate}</h6>
                                <h6 className="font-weight-500 common-black-1">{data.escalatedTime}</h6>
                              </div>
                            </div>))}
                          </Col>))}
                        </Row> */}
                        <TreeUiComponent
                          rootTitle="Escalation Matrix"
                          nodes={this.__generateTreeNodes(
                            this.state.escalatedList,
                            reportData?.escalation_level_timeline
                          )}
                          callback={this.__renderTreeTemplate}
                        />
                      </div>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {(reportData.overdue != undefined &&
                  reportData?.overdue != 0) ||
                (reportData.deviation != undefined &&
                  reportData?.deviation != 0) ? (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work vertical-avatar-border-remove"
                    contentStyle={{
                      paddingTop: 0,
                      boxShadow: "none",
                    }}
                    iconStyle={{
                      background: "#F96A1A",
                      color: "#fff",
                    }}
                    icon={
                      <img src={OverdueIcon} className="px-2 w-100  h-100" />
                    }
                    contentArrowStyle={{ border: "none" }}
                  >
                    <div className="rd-box">
                      <div
                        style={{
                          background: "rgba(217, 217, 217, 0.5)",
                          borderRadius: "28px",
                          width: "max-content",
                        }}
                        className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      >
                        {this.state.resolveFlag === "open"
                          ? "Overdue"
                          : "Deviation"}
                      </div>
                      <div className="mt-5"></div>
                      <div className="rd-box-head d-flex align-items-center">
                        <span style={{ marginRight: "0.5vw" }}>
                          {this.state.resolveFlag === "open"
                            ? "Overdue"
                            : "Deviation"}{" "}
                          {"By"}
                        </span>
                        <div
                          style={{
                            border: "1px solid #FF5C00",
                            borderRadius: "28px",
                            color: "#FF5C00",
                            padding: "10px 21px",
                          }}
                          className="px-3 py-2 font-weight-500 common-red-3 ml-4"
                        >
                          <span>
                            {this.state.resolveFlag === "open"
                              ? reportData.overdue
                              : reportData.deviation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </VerticalTimelineElement>
                ) : null}
                {/* {reportData.invited_user?.length > 0 ? <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    height: "4vw",
                    paddingTop: 0,
                    boxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff"
                  }}
                  icon={<Info />}
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">

                    <div style={{
                      background: "rgba(217, 217, 217, 0.5)",
                      borderRadius: "28px"
                    }} className="px-3 py-2 common-black-1 font-weight-500 text-center">Invite User Details</div>
                    <div className="mt-5">
                      <Row>
                        {reportData.invited_user?.map((image, index) => (
                          <Col key={`user-name-${index}`} md={1} className="text-left">
                            <div className="image-box">
                              <img
                                className="image-box"
                                src={image.userpic}
                                alt=""
                                data-tip
                                data-for={`user-name-${index}`}
                              />

                              <ReactTooltip
                                id={`user-name-${index}`}
                                effect="solid"
                                place="bottom"
                              >
                                <>
                                  <span>{image.username}</span>
                                </>
                              </ReactTooltip>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </VerticalTimelineElement> : null} */}

                <VerticalTimelineElement
                  className="vertical-timeline-element--work vertical-avatar-border-remove"
                  contentStyle={{
                    paddingTop: 0,
                    boxShadow: "none",
                  }}
                  iconStyle={{
                    background: "#F96A1A",
                    color: "#fff",
                  }}
                  icon={
                    <img
                      src={DiscussIcon}
                      className="px-2 w-100  h-100"
                      alt=""
                    />
                  }
                  contentArrowStyle={{ border: "none" }}
                >
                  <div className="rd-box">
                    <div
                      style={{
                        background: "rgba(217, 217, 217, 0.5)",
                        borderRadius: "28px",
                        width: "max-content",
                      }}
                      className="px-3 py-2 common-black-1 font-weight-500  text-center"
                      id="discuss"
                      ref={this.myRef}
                    >
                      Discuss
                    </div>
                    <div className="mt-5">
                      <div className="horizontal-timeline-wrapper shadow-none border mt-5 px-5 pt-5">
                        <div className="rd-box">
                          <div>
                            {this.state.comment == 0 ? (
                              ""
                            ) : (
                              <div className="d-flex">
                                <ChevronLeft
                                  className="cursor-pointer common-orange"
                                  onClick={() => this.backToMainComment()}
                                />{" "}
                                Comments details
                              </div>
                            )}
                            {/* {this.state.total_number > 3 &&
                  this.state.comment == 0 && (
                    <div>
                      {this.state.allComments ? (
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            this.CommentList();
                            this.setState({ allComments: false });
                          }}
                          className="cursor-pointer common-orange"
                        >
                          Back To Main Comments
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            this.CommentList(this.state.total_number);
                            this.setState({ allComments: true });
                          }}
                          className="cursor-pointer common-orange"
                        >
                          Show All Comments
                        </a>
                      )}
                    </div>
                  )} */}
                          </div>

                          <Scrollbars
                            style={{ height: `19vw` }}
                            ref={(c) => {
                              this.myChatRef = c;
                            }}
                          >
                            <div className="mr-5 d-flex flex-column-reverse">
                              {!this.state.dataLoading &&
                              this.state.comment == 0 &&
                              this.state.commentlist != "" ? (
                                this.state?.commentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }

                                  return (
                                    <div key={idx} id={el.comment_id}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box d-flex inner-box justify-content-center pt-5">
                                  {this.state.comment == 0 &&
                                    this.state.commentlist == "" && (
                                      <div className="box inner-box">
                                        <p>No Comments Available</p>{" "}
                                      </div>
                                    )}
                                </div>
                              )}
                              {!this.state.dataLoading &&
                              this.state.comment == 1 &&
                              this.state.subcommentlist != "" ? (
                                this.state.subcommentlist.map((el, idx) => {
                                  const position = el.delete_flag
                                    ? "right"
                                    : "left";
                                  const callbacks = {
                                    delete: this.CommentRemove,
                                    like:
                                      el.like_flag != 1
                                        ? this.LikeCommentAdd
                                        : this.LikeCommentRemove,
                                    reply: this.togglecommentId,
                                  };

                                  if (!el.delete_flag) {
                                    delete callbacks.delete;
                                  }
                                  return (
                                    <div key={idx}>
                                      <ChatMessageBox
                                        message={el.comment}
                                        isLiked={el.like_flag}
                                        likeCount={el.like_number}
                                        hasReplies={el.reply_number}
                                        commentTime={el.comment_time}
                                        repliesCount={el.reply_number}
                                        user_name={el.user_name}
                                        user_pic={el.user_pic}
                                        position={position}
                                        callbacks={callbacks}
                                        callbacksParams={{
                                          delete: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          like: {
                                            id: el.comment_id,
                                            count: 1,
                                          },
                                          reply: {
                                            id: el.comment_id,
                                          },
                                        }}
                                      />
                                      {el.subComment.map((sub, i) => {
                                        const callbacks = {
                                          delete: this.CommentRemove,
                                          like:
                                            sub.like_flag != 1
                                              ? this.LikeCommentAdd
                                              : this.LikeCommentRemove,
                                        };
                                        if (!sub.delete_flag) {
                                          delete callbacks.delete;
                                        }
                                        return (
                                          <div
                                            className={` ${
                                              position === "right"
                                                ? "mr-5 pr-5"
                                                : "ml-5 pl-5"
                                            }`}
                                            key={i}
                                          >
                                            <ChatMessageBox
                                              message={sub.comment}
                                              isLiked={sub.like_flag}
                                              likeCount={sub.like_number}
                                              hasReplies={sub.reply_number}
                                              commentTime={sub.comment_time}
                                              repliesCount={sub.reply_number}
                                              user_name={sub.user_name}
                                              position={position}
                                              user_pic={sub.user_pic}
                                              callbacks={callbacks}
                                              callbacksParams={{
                                                delete: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                                like: {
                                                  id: sub.comment_id,
                                                  count: 2,
                                                },
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="box inner-box">
                                  {this.state.comment == 1 &&
                                    this.state.subcommentlist == "" && (
                                      <p>No Sub Comments Available</p>
                                    )}
                                </div>
                              )}
                            </div>
                          </Scrollbars>
                          <div className="common-seprator-grey-3 my-4"></div>
                          {this.state.comment == 0 ? (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a comment"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      commentAdd: e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.CommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                      }
                                    }
                                  }}
                                  value={this.state.commentAdd}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon cursor-pointer"
                                  onClick={(e) => {
                                    if (e.detail == 1) {
                                      this.CommentAdd();
                                    }
                                  }}
                                  disabled={this.state.isCommentApiLoading}
                                  style={{
                                    color: "59B0F6",
                                    height: "35px",
                                    width: "35px",
                                  }}
                                />
                              </div>
                              {!!this.state.commentError && (
                                <p className="inputErrorMsg">
                                  {requiredMessage}
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className="align-items-center comments-input d-flex input-block">
                              <div className="w-100 px-4 border-right">
                                <input
                                  name="safety-thought"
                                  type="text"
                                  placeholder="Write a Reply"
                                  className="form-control border-0"
                                  multiple
                                  onChange={(e) =>
                                    this.setState({
                                      replycommentAdd:
                                        e.target.value.trimStart(),
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      console.log("checking", inputMessageFlag);
                                      if (inputMessageFlag === 0) {
                                        inputMessageFlag = 1;
                                        console.log(
                                          "checking",
                                          inputMessageFlag
                                        );

                                        setTimeout(() => {
                                          this.ReplyCommentAdd();
                                          inputMessageFlag = 0;
                                        }, 500);
                                      }
                                    }
                                  }}
                                  value={this.state.replycommentAdd}
                                />
                              </div>
                              <div className="px-4 send-btn">
                                <Send
                                  aria-disabled="true"
                                  className="send-icon  cursor-pointer"
                                  disabled={this.state.isCommentApiLoading}
                                  onClick={(e) => {
                                    if (
                                      this.state?.replycommentAdd != "" &&
                                      e.detail == 1
                                    ) {
                                      this.ReplyCommentAdd();
                                    }
                                  }}
                                  style={{
                                    color: "59B0F6",
                                    height: "35px",
                                    width: "35px",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </VerticalTimelineElement>
              </VerticalTimeline>

              <br />
              <br />
            </div>
            <div className="rd-right-content">
              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <div className="text-center mb-3">
                  <span className="common-black-1 font-weight-500 font-20">
                    Reported By
                  </span>
                  <div className="d-flex flex-column align-items-center">
                    <OverlayTrigger
                      trigger="hover"
                      placement="left"
                      rootClose={true}
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          style={{ width: "15vw", borderRadius: "10px" }}
                        >
                          <Container>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Date :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {reportData.submission_date}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Time :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {reportData.submission_time}
                              </span>
                            </div>
                            <div className="my-3">
                              <span className="common-black-1 font-weight-500 font-16">
                                Reported Month :
                              </span>
                              <span className="common-black-1 ml-3 font-16">
                                {reportData.reportedmonth_name}
                              </span>
                            </div>
                          </Container>
                        </Popover>
                      }
                    >
                      <img
                        src={reportData.reported_by_pic}
                        className="user-icon my-3 cursor-pointer"
                      />
                    </OverlayTrigger>
                  </div>
                  <span className="common-black-1  font-18">
                    {reportData.reported_by}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-1">
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        ID :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails.emp_id}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Department :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails?.field?.split(":")[1] ??
                          "NA"}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Type :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails.type ?? "NA"}
                      </span>
                    </div>
                    <div>
                      <span className="common-black-1 font-weight-500 font-16">
                        Designation :
                      </span>
                      <span className="common-black-1 ml-3 font-16">
                        {this.state.assignedDetails.designation ?? "NA"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rd-box shadow-none border liked-btn"
                style={{ borderRadius: "1vw" }}
              >
                <div className="side-invite-user">
                  <div className="image-box">
                    <span
                      className="common-black-1 font-weight-500 font-20"
                      style={{ marginRight: "0.5vw" }}
                    >{`Badge (${this.state.likeNumber})`}</span>
                    {this.state.userLike != "" ? (
                      this.state.userLike?.map(
                        (item, index) =>
                          // <div className="user-list" key={index}>
                          index < 5 && (
                            // <img src={item.user_profile} alt={item.user_name} />

                            <React.Fragment>
                              <img
                                src={item.user_profile}
                                alt=""
                                data-tip
                                data-for={`user-name-${index}`}
                              />

                              <ReactTooltip
                                effect="solid"
                                place="right"
                                id={`user-name-${index}`}
                              >
                                <>
                                  <span>{item.user_name}</span>
                                </>
                              </ReactTooltip>
                            </React.Fragment>
                          )
                        // </div>
                      )
                    ) : (
                      <p className="data-not-present">Badge not available </p>
                    )}
                    {this.state.userLike.length > 5 ? (
                      <span
                        className="cursor-pointer"
                        onClick={() => this.showUserLikedPopup()}
                      >{`+${this.state.userLike.length - 5} More`}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* <div className="rd-box">
              <div className="rd-box-head rd-box-head1">
                <h3>Invited User</h3>
                {this.state.resolveFlag == "resolve" ? (
                  <div className="rd-head-right rd-head-left resolve">
                    <Button
                      variant="outline-primary"
                      className="invite-btn"
                      onClick={this.openInviteUser}
                    >
                      INVITE MORE USER
                    </Button>
                  </div>
                ) : (
                  <div className="rd-head-right rd-head-left">
                    <Button
                      variant="outline-primary"
                      className="invite-btn"
                      onClick={this.openInviteUser}
                    >
                      INVITE MORE USER
                    </Button>
                  </div>
                )}
              </div>

              <div className="rd-left-bottom side-invite-user">
                {this.state.reportDetail.invited_user &&
                  this.state.reportDetail.invited_user.length == 0 ? (
                  <p className="data-not-present">Users not available </p>
                ) : (
                  this.state.reportDetail.invited_user &&
                  this.state.reportDetail.invited_user.map((data, index) => (
                    <div className="user-list">
                      <img src={data.userpic} alt="" />
                      <h6 className="user-name">{data.username}</h6>
                      {this.state.resolveFlag == "resolve" ? (
                        <Button
                          className="remove-btn resolve"
                          onClick={() => this.removeInviteUser(data.userid)}
                        >
                          <i
                            className="fa fa-trash delete-icon"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      ) : (
                        <Button
                          className="remove-btn"
                          onClick={() => this.removeInviteUser(data.userid)}
                        >
                          <i
                            className="fa fa-trash delete-icon"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div> */}
              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    eventKey="0"
                    as={Button}
                    variant="link"
                    onClick={() =>
                      this.setState({
                        accordionOpen: !this.state.accordionOpen,
                      })
                    }
                  >
                    <div className="rd-box-head1">
                      <h3> <Triangle className="cursor-pointer" /> &nbsp; {reportData.activity} | Analysis</h3>
                      {this.state.accordionOpen ? (
                        <ChevronDown className="indicator-icon" />
                      ) : (
                        <ChevronRight className="indicator-icon" />
                      )}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="rd-left-bottom">
                      
                      <Row>
                        <Col>
                          <div className="report-detail-graph-label">
                            IRA Analysis 
                          </div>
                          {reportData.fa_number == 0 &&
                          reportData.mtc_number == 0 &&
                          reportData.rta_number == 0 &&
                          reportData.lti_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <>
                              <Row>
                                <Col sm={6}>
                                  <div style={{ width: "9vw" }}>
                                    {config.isChartJsEnabled ? (
                                      this.CountDoughnut(analysisChartData)
                                    ) : (
                                      <HighchartsReact
                                        highcharts={Highcharts}
                                        options={analysisChart}
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FF4242" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.fa_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    FA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#086992" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.mtc_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    MTC
                                  </div>
                                </Col>
                                <Col
                                  sm={3}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#FAAC15" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.rta_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    TRA
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#18DA07" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.lti_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    LTI
                                  </div>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#C511FF" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {
                                        reportData.activityAnalysis
                                          ?.occupational_illness_number
                                      }
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text   mr-5">
                                    OI
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#1589EE" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value mr-1">
                                      {
                                        reportData.activityAnalysis
                                          ?.first_aid_number
                                      }
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text mr-2">
                                    First Aid
                                  </div>

                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{
                                        backgroundColor: "rgb(161, 255, 206)",
                                      }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {
                                        reportData.activityAnalysis
                                          ?.notable_number
                                      }{" "}
                                      &nbsp;
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Notable
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                          <div className="report-detail-graph-label">
                            NM Analysis
                          </div>
                          {reportData.nm_ua_number == 0 &&
                          reportData.nm_uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    <div style={{ width: "9vw" }}>
                                      {config.isChartJsEnabled ? (
                                        this.CountDoughnut(nmuaUcChartData)
                                      ) : (
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={nmuaUcChart}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            reportData.nm_ua_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {reportData.nm_ua_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{
                                          backgroundColor:
                                            reportData.nm_uc_color,
                                        }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {reportData.nm_uc_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                          <div className="report-detail-graph-label">
                            UA Vs UC Analysis
                          </div>
                          {reportData.ua_number == 0 &&
                          reportData.uc_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <Row
                                  style={{
                                    borderRight:
                                      "0.03vw solid rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <Col sm={6}>
                                    <div style={{ width: "9vw" }}>
                                      {config.isChartJsEnabled ? (
                                        this.CountDoughnut(uaUcChartData)
                                      ) : (
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={uaUcChart}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="report-detail-graph-legend-block"
                                  >
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#C7AC83" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {reportData.ua_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Act
                                    </div>
                                    <div className="report-detail-graph-legend-inner-block">
                                      <div
                                        className="report-detail-graph-legend-box"
                                        style={{ backgroundColor: "#554040" }}
                                      ></div>
                                      <div className="report-detail-graph-legend-value">
                                        {reportData.uc_number}
                                      </div>
                                    </div>
                                    <div className="report-detail-graph-legend-text">
                                      Unsafe Condition
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                          <div className="report-detail-graph-label">
                            Risk Level Analysis
                          </div>
                          {reportData.extreme_number == 0 &&
                          reportData.high_number == 0 &&
                          reportData.medium_number == 0 &&
                          reportData.low_number == 0 ? (
                            <div className="graph-not-available-text">
                              Graph not available
                            </div>
                          ) : (
                            <Row>
                              <Col>
                                <div className="sm-graph-modal-labels"> </div>
                                {config.isChartJsEnabled ? (
                                  <Bar
                                    data={severityChartData}
                                    plugins={[ChartDataLabels]}
                                    options={barGraphOptions}
                                    height={150}
                                  />
                                ) : (
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={severityChart}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Good Act & TBT Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.gpcount.map((data) => data?.ga_count)}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.gpcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={gpCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> GA </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.tbtcount.map(
                          (data) => data.tbt_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.tbtcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={tbtCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> TBT </label>
                  </div>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Internal & External Training Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.trainingInternalCount.map(
                          (data) => data.training_internal_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingInternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={internalCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Internal </label>
                  </div>
                </Col>
                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingExternalCount.map(
                            (data) => data.training_external_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingExternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={externalCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> External </label>
                  </div>
                </Col>
              </div>

              <div
                className="rd-box shadow-none border"
                style={{ borderRadius: "1vw" }}
              >
                <label className="common-black-1 font-weight-500">
                  {" "}
                  Employee & Contractor Training Count{" "}
                </label>
                <Col sm={6}>
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingEmployeeCount.map(
                            (data) => data.training_employee_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingEmployeeCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={employeeCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Employee </label>
                  </div>
                </Col>

                <Col
                  sm={6}
                  style={{
                    marginLeft: "10vw",
                    marginTop: config.isChartJsEnabled ? "-9.10vw" : "-10.70vw",
                  }}
                >
                  <div style={{ width: "9vw" }}>
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingContractorCount.map(
                            (data) => data.training_contractor_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"2.1vw"}
                        color={this.state.trainingContractorCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={contractorCount}
                      />
                    )}
                  </div>
                  <div className="d-flex-center">
                    <label> Contractor </label>
                  </div>
                </Col>
              </div>

              <Modal
                centered
                show={this.state.deletePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to delete this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() => this.deleteReport(this.state.soiId)}
                    >
                      YES, DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        {this.state.show ? (
          <ResolvePopup
            show={this.state.show}
            modalClose={this.openResolve}
            reportId={this.state.soiId}
            reportingDetails
            data={this.state.reportDetail}
          />
        ) : null}

        {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.soiId}
            onAddNewUsers={this._addUsersToList}
            invitedUsersList={this.state.reportDetail.invited_user}
            removeInviteUser={this.removeInviteUser}
            resolveFlag={this.state.resolveFlag}
            moduleType={"report"}
          />
        ) : null}

        <Modal centered show={this.state.deletePopup} className="submit-modal">
          <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

          <Modal.Body>
            <img
              className="submit-img"
              src={require("assets/images/delete-popup.svg")}
            />
            <p className="submit-text">Are you sure you want to delete this?</p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this.hideModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={() => this.deleteReport(this.state.soiId)}
              >
                YES, DELETE
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.countModalParams.isOpen}
          className="reportPopup "
          onHide={() =>
            this.setState({
              countModalParams: {
                isOpen: false,
                moduleName: "",
              },
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.countModalParams.moduleName} wise   &nbsp; {reportData.activity} | Analysis
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Bar
              plugins={[ChartDataLabels]}
              options={barGraphOptions}
              
              {...this.generateBarData(this.state.countModalParams.moduleData)}
              height={150}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// This comment is to remove

export default withRouter(ReportingDetail);

export const GetPopoverFrame = ({
  element,
  mappingKeys = {},
  position = "right",
  triggerType = null,
  customElement = "",
  returnTriggerOnly = false,
}) => {
  const [isModalEnabled, setIsModalEnabled] = useState(false);
  const elements = mappingKeys() ?? {};

  return (
    <>
      {returnTriggerOnly ? (
        <div title="User Details">
          {" "}
          <Eye
            className="cursor-pointer w-10 position-absolute d-flex"
            style={{
              top: "-13px",
              right: "25%",
              marginLeft: "127px",
            }}
            id="properover-content"
            title="user details"
            onClick={() => setIsModalEnabled(true)}
          />{" "}
        </div>
      ) : (
        <div
          className="d-flex-center position-relative"
          id="properover-content"
        >
          <div>{element}</div>
          <div title="User Details">
            <Eye
              className="cursor-pointer w-10 position-absolute"
              style={{
                top: "-13px",
                right: "20%",
              }}
              onClick={() => setIsModalEnabled(true)}
            />
          </div>
        </div>
      )}

      <SLDModal
        style={{
          maxWidth: "auto",
        }}
        isOpen={isModalEnabled}
        footer={[
          <SLDButton label="Close" onClick={() => setIsModalEnabled(false)} />,
        ]}
        // onRequestClose={() => setIsModalEnabled(false)}
        heading="User Details"
      >
        <Container>
          {elements &&
            Object?.entries(elements)?.map((el) => (
              <div className="my-3">
                <span className="common-black-1 font-weight-500 font-18">
                  {el[0]} :
                </span>
                <span className="common-black-1 ml-3 font-18">{el[1]}</span>
              </div>
            ))}
          {customElement}
        </Container>
      </SLDModal>
    </>
  );
};
